class Application
  options:
    animationStep: 200
  selectors:
    order: '[data-order]'
  constructor: () ->
    @_scroll() unless @scrollController

  _scroll: ->
    @scrollController = new ScrollMagic.Controller()
    $('.scroll-section').each (index, section) =>
      scene = new ScrollMagic.Scene
        triggerElement: "##{section.id}"
        triggerHook: 0.75
      .addTo(@scrollController)

      scene.on 'start', (event) =>
        section = event.currentTarget.triggerElement()
        anchor = $(section).attr('id')
        @_show anchor

  _show: (anchor) ->
    items = $(Application::selectors.order, $("##{anchor}"))
    items.sort (a, b) ->
      $(a).data('order') > $(b).data('order')
    .each (index, item) =>
      $(item)
      .delay @_step(index)
      .animate {opacity: 1}, {
        complete: () =>
          afterOrder = $(item).data('after-order')
          if afterOrder and not $(item).data('animated')
            @[afterOrder]()
            $(item).data('animated', true)
      }

  _step: (index) ->
    step = Application::options.animationStep
    index * step

$ ->
  window.application = new Application()
